/* eslint-disable */
import PermissionsClass from '@/security/Permission-classes'
import Permissions from '@/security/permissions'

export class SliderPermissions extends PermissionsClass {
  constructor(currentUser) {
    super(currentUser, {
      read: Permissions.sliderRead,
      create: Permissions.sliderCreate,
      edit: Permissions.sliderEdit,
      delete: Permissions.sliderDestroy
    })
  }
}
